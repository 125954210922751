import { useRef, useEffect, useState } from "react";
import { FLAGS } from "../constants";
import useOnScreen from "../hooks/useOnScreen";
import { IS_MOBILE } from "../supports";
import Info from "./Info";

function usePreload(url, ref) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {

        function load() {
            setLoading(false);
        }

        if (url && ref.current) {
            var img = ref.current;
            img.src = url;
            img.onload = load;
        }

    }, [url]);

    return {
        loading,
    }
    
}


export default function Image({ title, filename, year, bit, meta, fullscreen, allowFullscreen = true }) {

    const img = useRef();
    const isIntersecting = useOnScreen(img);
    const [src, setSrc] = useState();
    const { loading } = usePreload(src, img);

    const full = allowFullscreen && (fullscreen || bit.includes(FLAGS.FULL_WIDTH));

    useEffect(() => {
        if (isIntersecting) {
            setSrc(`/media/${filename}${IS_MOBILE ? `?nf_resize=fit&w=${Math.min(window.innerWidth, meta.width)}` : ''}`);
        }
    }, [isIntersecting, title]);

    return <div className={`media-item media-item-image ${full ? 'full-width' : ''} ${loading ? 'border' : ''}`}>
        {isIntersecting && <Info title={title} year={year} />}
        <img
            width={meta.width}
            height={meta.height}
            className={`image ${loading ? 'hide' : 'show'}`}
            // loading="lazy"
            ref={img}
            alt={title}
            src={`/placeholder.jpg`}
            style={{"--ar": meta.width/meta.height}}
        />
    </div>

}