import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FLAGS } from "../constants";
import useOnScreen from "../hooks/useOnScreen";
import Info from "./Info";

export default function Audio({ id, type, title, filename, year, fullscreen, bit, allowFullscreen = true }) {
    const ref = useRef();

    const isIntersecting = useOnScreen(ref);

    const full = allowFullscreen && (fullscreen || bit.includes(FLAGS.FULL_WIDTH));

    useEffect(() => {
        if (isIntersecting) {
            ref.current.src = ref.current.dataset.src;
        }
    }, [isIntersecting])


    return <div className={`media-item media-item-audio ${full ? 'full-width' : ''}`} >
        {isIntersecting && <Info title={title} year={year} />}
        <div className="audio-player">
            <audio ref={ref} controls data-src={`/media/${filename}`} alt={title} />
        </div>
    </div>
}