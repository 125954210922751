import { useEffect, useState } from "react";

export default function useOnScreen(ref, rootMargin = "25px") {
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
        if (ref.current) {
            const currentRef = ref.current;
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIntersecting(entry.isIntersecting);
                },
                {
                    rootMargin
                }
            );
            if (ref.current) {
                observer.observe(currentRef);
            }
            return () => {
                observer.unobserve(currentRef);
            };
        }
    }, []);
    return isIntersecting;
}