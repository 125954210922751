import React, { useEffect, useState } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import Portal from "./Portal";
import { IS_MOBILE } from "../supports";

import NavIcon from "./menu.svg";

export function BasicNav() {
  const { pathname } = useLocation()
  return <nav>
    {pathname !== "/" && <NavLink to={`/`}>Home</NavLink>}
    <NavLink to={`/about`}>About</NavLink>
    <NavLink to={`/discography`}>Discography</NavLink>
    <NavLink to={`/commission`}>Work With Me</NavLink>
    <NavLink to={`/contact`}>Contact</NavLink>
  </nav>
}

export function HomeNav() {
  const { pathname } = useLocation()
  return <nav style={{textAlign: 'center', padding: '1vw'}}>
    {pathname !== "/" && <NavLink to={`/`}>Home</NavLink>}
    <NavLink to={`/about`}>About</NavLink>
    <NavLink to={`/discography`}>Discography</NavLink>
    <NavLink to={`/commission`}>Work With Me</NavLink>
    <NavLink to={`/contact`}>Contact</NavLink>
  </nav>
}

export default function Navigation() {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location])

  if (IS_MOBILE) {
    return <>
      <button onClick={() => setOpen(!open)}><img src={NavIcon} alt="menu" /></button>
      <Portal>
        <div className={`mobileNav ${open ? 'show' : ''}`}>
          <BasicNav />
        </div>
      </Portal>
    </>
  }

  return <BasicNav />
}
