export function populateYears(a, year) {
  a.push({
    year,
    articles: ARTICLES.filter((article) => article.year === year)
  });
  return a;
}

export function getYears(a, b) {
  a.add(b.year);
  return a;
}

export const FLAGS = {
  FULL_WIDTH: 'f',
  AUTO_PLAY: "a",
  CONTROLS: "c",
  LOOP: "l",
}

import FOLIO from "./portfolio.json";
export const PORTFOLIO = FOLIO.sort((a, b) => ((b.year * 100) + b.sort) - ((a.year * 100) + a.sort));

export const ALL_ARTICLES = FOLIO.reduce((a, article) => {
  a[article.id] = article;
  return a;
}, {});

const ALBUM_SIGNIFIER = [
  'SPARK_',
  'WNY_',
  'WINNIE_THE_SHIT_'
]

export function isAlbum(item) {
  return ALBUM_SIGNIFIER.filter(k => item.project.includes(k)).length > 0;
}