import { useRef, useEffect } from "react";
import { FLAGS } from "../constants";
import useOnScreen from "../hooks/useOnScreen";
import { IOS_SAFARI } from "../supports";
import Image from "./Image";
import Info from "./Info";

import PlayIcon from "./play.svg"

export default function Video(item) {
    const ref = useRef();
    const { id, type, title, filename, year, bit, fullscreen, autoplay, preview, muted = true, allowFullscreen = true } = item;

    const full = allowFullscreen && (fullscreen || bit.includes(FLAGS.FULL_WIDTH));
    const play = autoplay || bit.includes(FLAGS.AUTO_PLAY);
    const loop = bit.includes(FLAGS.LOOP);
    const controls = !bit.includes(FLAGS.CONTROLS);

    const isIntersecting = useOnScreen(ref);

    useEffect(() => {
        if (ref.current && isIntersecting) {
            ref.current.src = ref.current.dataset.src;
        }
    }, [isIntersecting])

    const playVideo = () => {
        if (ref.current.paused) {
            ref.current.play();
        } else {
            ref.current.pause();
        }
    }

    return <div className={`media-item media-item-video ${full ? 'full-width' : ''}`}>
        {isIntersecting && <Info title={title} year={year} />}
        {!full && <>
            <Image {...preview} allowFullscreen={allowFullscreen} />
            <img
                alt="This is a video"
                src={PlayIcon}
                style={{
                    width: '32px',
                    position: 'absolute',
                    bottom: '16px',
                    left: '12px',
                    margin: 0,
                    lineHeight: 0,
                }} />
        </>
        }
        {full && !IOS_SAFARI && <video
            ref={ref}
            controls={controls}
            autoPlay={play}
            loop={loop}
            muted={muted}
            data-src={`/media/${filename}`}
            alt={title}
            onClick={playVideo}
        />
        }
        {full && IOS_SAFARI && <video
            ref={ref}
            loop={loop}
            muted={muted}
            controls={true}
            autoPlay={autoplay}
            playsInline={autoplay}
            data-src={`/media/safari/${filename.replace(".webm", ".mp4")}`}
            alt={title}
            onClick={playVideo}
        />
        }
    </div >
}
