import React from "react";
import { Link } from "react-router-dom";
import { FLAGS } from "../constants";
import Audio from "./Audio";
import Image from "./Image";
import Video from "./Video";

const elements = {
    png: Image,
    jpg: Image,
    webp: Image,
    mp3: Audio,
    mp4: Video,
    webm: Video,
}

export default function MediaLink({allowFullscreen = true, path="/work", ...item }) {
    
    const Component = elements[item.type];

    const full = allowFullscreen && item.bit.includes(FLAGS.FULL_WIDTH);

    return <Link title={item.title} className={`${full ? 'full-width' : ''}`} to={`${path}/${item.id}`}><Component {...item} allowFullscreen={allowFullscreen} /></Link>
  }