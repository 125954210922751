import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

export default function Portal ({ children, root = 'overlay' }) {

    const el = useMemo(()=>document.createElement("div"), []);
    const rootElement = useMemo(()=>document.getElementById(root), [root]);

    useEffect(()=>{
        rootElement.appendChild(el);
        return () => {
            rootElement.removeChild(el);
        }
    },[rootElement, el])

    return createPortal(children, el);

}