import { lazy, Suspense } from "react";

import "./styles.scss";

import {
  Route,
  BrowserRouter as Router,
  Routes,
  NavLink,
  useLocation,
  Link
} from "react-router-dom";

import Navigation, { BasicNav, HomeNav } from "./components/Nav";
import Portfolio from "./components/Portfolio";
import { PORTFOLIO } from "./constants";
import { Helmet } from "react-helmet";
import { IS_MOBILE } from "./supports";
const Warp = lazy(() => import('./components/Warp'));
const Contact = lazy(() => import('./components/Contact'));
const Statement = lazy(() => import('./Statement'));
const Work = lazy(() => import('./components/Work'));
const Album = lazy(() => import('./components/Album'));
const Privacy = lazy(() => import('./Privacy'));
const Discography = lazy(() => import('./Discography'));
const Licensing = lazy(() => import('./Licensing'));
const Linktree = lazy(() => import('./components/Linktree'));

function Header() {
  return (
    <header>
      <h1><NavLink to="/">Matthew Willox</NavLink></h1>
      <h2>Multidisciplinary Artist</h2>
    </header>
  );
}

const SHOW_NAV = [
  '/about',
  '/contact',
  '/commission',
  '/privacy',
  '/discography',
  '/linktree'
]

function TopNav() {
  const { pathname } = useLocation();

  if (!SHOW_NAV.includes(pathname)) {
    return <></>
  }

  return <div className="top">
    <Header />
    <Navigation />
  </div>
}

const HIDE_FOOTER = []



function MatthewWillox() {

  return <>
    {IS_MOBILE && <div className="top"><div></div><Navigation /></div>}
    <div style={{ display: 'grid', placeContent: 'center', height: '40vw', textAlign: 'center', marginTop: IS_MOBILE ? `-10vw` : '' }}>
      <Helmet>
        <title>Matthew Willox | Artist</title>
        <meta name="description" content="This is the portfolio of Matthew Willox, a multidisciplinary artist living in Edmonton, Alberta, Canada." />
        <meta property="og:title" content="Matthew Willox | Multidisciplinary Artist" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://matthewwillox.art/" />
        <meta property="og:image" content="https://matthewwillox.art/preview.jpg" />
        <meta property="og:description" content="This is the portfolio of Matthew Willox, a multidisciplinary artist living in Edmonton, Alberta, Canada."></meta>
      </Helmet>
      <Suspense><Warp /></Suspense>
    </div>
    {!IS_MOBILE && <HomeNav />}
    <Portfolio items={PORTFOLIO} />
  </>
}

function Footer() {
  const { pathname } = useLocation();

  if (HIDE_FOOTER.includes(pathname)) {
    return null;
  }

  return <footer className="bottom">
    <div>
      <a href="https://www.linkedin.com/in/matthew-ryan-willox/" target="_blank" rel="nofollow">LinkedIn</a> | <a href="https://github.com/mwmwmw" target="_blank" rel="nofollow">GitHub</a> | <a href="https://www.instagram.com/matthewwillox/" target="_blank" rel="nofollow">Instagram</a> | <a href="https://codepen.io/mwmwmw" target="_blank" rel="nofollow">Codepen</a>
    </div>
    <>&copy;</>{new Date().getFullYear()} Matthew Willox
  </footer>
}

export default function App() {
  return (
    <>
      <Router>
        <TopNav />
        <Routes>
          <Route path="/" element={<MatthewWillox />} />
          <Route path="/work/:id" element={<Suspense><Work /></Suspense>} />
          <Route path="/about" element={<Suspense><Statement /></Suspense>} />
          <Route path="/contact" element={<>
            <Helmet>
              <title>Matthew Willox | Contact</title>
              <meta name="description" content={"This is the portfolio of Matthew Willox, a multidisciplinary artist living in Edmonton, Alberta, Canada."} />
            </Helmet>
            <Suspense><Contact /></Suspense>
          </>} />
          <Route path="/discography" element={<Suspense><Discography /></Suspense>} />
          <Route path="/discography/:id" element={<Suspense><Album /></Suspense>} />
          <Route path="/commission" element={<Suspense><Licensing /></Suspense>} />
          <Route path="/privacy" element={<Suspense><Privacy /></Suspense>} />
          <Route path="/linktree" element={<Suspense><Linktree /></Suspense>} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
