import React from "react";
import MediaLink from "./MediaLink";

export default function Portfolio({items = [], allowFullscreen = true}) {
    return <div className="portfolio">
      {items.map((f) => <MediaLink key={f.filename} {...f} allowFullscreen={allowFullscreen}/>)}
    </div>
  }

  export function SubPortfolio({items = [], allowFullscreen = true, style, className}) {
    return <div className={`sub-portfolio ${className}`}style={style}>
      {items.map((f) => <MediaLink key={f.filename} {...f} allowFullscreen={allowFullscreen}/>)}
    </div>
  }

  export function Albums({items = [], allowFullscreen = true, style, className}) {
    return <div className={`sub-portfolio ${className}`}style={style}>
      {items.map((f) => <MediaLink path="/discography" key={f.filename} {...f} allowFullscreen={allowFullscreen}/>)}
    </div>
  }